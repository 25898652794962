<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="6">
                    <b-card class="soft">
                        <template #header>
                            {{ $t('mother') }}: <strong>{{ getObjectValue(studentProgram, 'mother_name') }}</strong>
                        </template>
                        <ValidationProvider name="mothers_job" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('profession_job')">
                                <b-form-input v-model="formData.mothers_job"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="mothers_education" rules="required" v-slot="{valid, errors}">
                            <b-form-group>
                                <label>{{ $t('education_status') }}</label>
                                <parameter-selectbox v-model="formData.mothers_education"
                                                     code="education_statuses"
                                                     sort="id"
                                                     :validate-error="errors[0]"
                                ></parameter-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="mothers_phone" rules="required" v-slot="{valid, errors}">
                            <b-form-group>
                                <label>{{ $t('contact_number') }}</label>
                                <b-form-input v-model="formData.mothers_phone"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card class="soft">
                        <template #header>
                            {{ $t('father') }}: <strong>{{ getObjectValue(studentProgram, 'father_name') }}</strong>
                        </template>
                        <ValidationProvider name="fathers_job" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('profession_job')">
                                <b-form-input v-model="formData.fathers_job"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="fathers_education" rules="required" v-slot="{valid, errors}">
                            <b-form-group>
                                <label>{{ $t('education_status') }}</label>
                                <parameter-selectbox v-model="formData.fathers_education"
                                                     code="education_statuses"
                                                     sort="id"
                                                     :validate-error="errors[0]"
                                ></parameter-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="fathers_phone" rules="required" v-slot="{valid, errors}">
                            <b-form-group>
                                <label>{{ $t('contact_number') }}</label>
                                <b-form-input v-model="formData.fathers_phone"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        },
        created() {
            this.formId = this.studentProgram.id;
            this.formData = {
                mothers_job: this.studentProgram.mothers_job,
                mothers_education: this.studentProgram.mothers_education,
                mothers_phone: this.studentProgram.mothers_phone,
                fathers_job: this.studentProgram.fathers_job,
                fathers_education: this.studentProgram.fathers_education,
                fathers_phone: this.studentProgram.fathers_phone
            }
        },
        data() {
            return {
                formId: null,
                formData: {},
                formProcess: false
            }
        },
        methods: {
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formProcess = true
                    StudentProgramService.updateFamily(this.formId, this.formData)
                                         .then(response => {
                                             this.$emit('updateFormSuccess', true);
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch((e) => {
                                             this.showErrors(e, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formProcess = false;
                                         })
                }
            }
        }
    }
</script>

